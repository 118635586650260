export const DASHBOARD_MODULE = 'Dashboard';

// Data Grid Configuration
/**
 * ROW LAYOUTS describes different types of row sizes
 * @typedef ROW_LAYOUTS
 * @property {string} layoutName
 */
export const ROW_LAYOUTS = ['Roomy', 'Comfortable', 'Compact'];
/**
 * PAGE SIZE describes different types of page size lengths
 * @typedef PAGE_SIZE
 * @property {string} size
 */
export const PAGE_SIZE = ['5', '10', '15', '20'];

// #region Local Storage
/**
 * NOTIFICATION KEY
 * @typedef NOTIFICATION_KEY
 * @property {string} value
 */
export const NOTIFICATION_KEY = 'Core.Notifications';
// #endregion

// Work box settings
/**
 * APPLICATION SHELL
 * @typedef APPLICATION_SHELL
 * @property {string} value
 */
export const APPLICATION_SHELL = 'application-shell';
// #region Index Db Databases

/**
 * Dashboard
 *   @typedef DASHBOARD
 * @property {string} value
 */
export const DASHBOARD = 'Dashboard';
/**
 * Application Configurations
 *   @typedef CORE_STORE
 * @property {string} value
 */
export const CORE_STORE = 'Core';
/** Facilities */
export const FACILITIES_MODULE = 'FACILITIES';
export const FACILITY_CODE = 'Core.FacilityCode';
export const FACILITY_AUTHORIZATION = 'Core.FacilityAuth';

/** Facility Lookup */
/** Settings */
export const ROW_LAYOUT = 'Core.DataGrid.RowLayout';
export const DEFAULT_LIMIT = 'Core.DataGrid.ItemsPerPage';
export const DROPDOWN_FILTER_THRESHOLD = 10;

/**
 *
 * Named Filter Key used for saving and receiving app configurations
 */
export const WR_NAMED_FILTER = 'WR.NamedFilter';

/** App Configs */
export const APP_CONFIG_GLOBAL = 'Global App Config';
export const APP_CONFIG_FACILITY = 'Facility App Config';
export const APP_CONFIG = 'App Config';
export const APP_CONFIG_MODIFICATION = 'Modify_App_Config';
export const LISTING_LIMIT = 10;
// #endregion

/**
 * GP QUEUE
 * @typedef GP_QUEUE
 * @property {string} value
 */
export const GP_QUEUE = 'MPGO_Queue';

/**
 * ASSETS IMAGES
 * @typedef ASSETS_IMAGES
 * @property {string} value
 */
export const ASSETS_IMAGES = 'assets-images';

/**
 * Const for lookups
 * Lookup_Selection_Mode used to for single/multiple selection of DataTable item
 * Equipment_LookUp_Mode used to configure things according to look or equipment module
 */
export const LookUp_Mode = {
  LookUp: 'LookUp',
  Page: 'page',
};
export const Lookup_Selection_Mode = {
  single: 'Single',
  multiple: 'Multiple',
};

/**
 * BOOLEAN_VALUES describes boolen values
 * @constant
 * @typedef BOOLEAN_VALUES
 * @property {string} text
 * @property {string} id
 * @return {string} True/False
 */
export const BOOLEAN_VALUES = [
  { label: '(All)', value: '' },
  { label: 'Checked', value: 'true' },
  { label: 'Not Checked', value: 'false' },
];

/** Export const Core
 *  @constant
 *  @type {string}
 *  @default
 */
export const Core_Module = 'Core';

/** GhaphQL_OPERATORS */
/** Export const CONTAINS_OP
 * @constant {string}
 */
export const CONTAINS_OP = 'eq';
/** Export const EQUALS_OP
 * @constant {string}
 */
export const EQUALS_OP = 'eq';
/** Export const NOT_EQUALS_OP
 * @constant {string}
 */
export const NOT_EQUALS_OP = 'neq';
/** Export const GREATER_THAN_OP
 * @constant {string}
 */
export const GREATER_THAN_OP = 'gt';
/** Export const GREATER_THAN_EQUAL_OP
 * @constant {string}
 */
export const GREATER_THAN_EQUAL_OP = 'gte';
/** Export const LESS_THAN_OP
 * @constant {string}
 */
export const LESS_THAN_OP = 'lt';
/** Export const LESS_THAN_EQUAL_OP
 * @constant {string}
 */
export const LESS_THAN_EQUAL_OP = 'lte';
/** Export const Starts_WITH_OP
 * @constant {string}
 */
export const STARTS_WITH_OP = 'str';
/** Export const ENDS_WITH_OP
 * @constant {string}
 */
export const ENDS_WITH_OP = 'end';

/** Preferences  */
export const PREFERENCES_TILELAYOUT = 'Preferences_TileLayout';

/** Dashboard  */
export const DASHBOARD_TILELAYOUT = 'Dashboard_TileLayout';

/** Authorization */
export const ACCESS_TOKEN = 'Core.AccessToken';
export const ID_TOKEN = 'Core.IdToken';
export const REFRESH_TOKEN = 'Core.RefreshToken';
export const SKIP_SPLASH = 'Core.SkipSplash';
export const AUTH_TOKENS = 'AuthorizationToken';
// Used in User Context
export const TOKEN_EXP = 'Core.TokenExp';
export const USER_DATA = 'Core.UserData';

/** Export const SYNC_PROCESS_DELAY
 *  @constant
 *  @type {string}
 *  @default
 */

export const SYNC_PROCESS_INTERVAL = {
  /**
   * Save App Config
   */
  APP_CONFIG_MODIFICATION: 120000,
};

/**
 * Default Grid filter operators:
 */
export const FilterOperators = {
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
  ],
  numeric: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterGteOperator', operator: 'gte' },
    { text: 'grid.filterGtOperator', operator: 'gt' },
    { text: 'grid.filterLteOperator', operator: 'lte' },
    { text: 'grid.filterLtOperator', operator: 'lt' },
  ],
  date: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
  ],
  boolean: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
  ],
};

/**
 * Workbox Startegy
 */
export const WorkboxStartegy = {
  NETWORKFIRST: 'networkFirst',
  CACHEFIRST: 'cacheFirst',
};

/**
 * Data Types
 */
export const DataTypes = {
  Text: 'text',
  TextArea: 'textArea',
  Numeric: 'numeric',
  Date: 'date',
  DateTime: 'dateTime',
  Boolean: 'boolean',
  MultiSelect: 'multiSelect',
  DropDown: 'dropDown',
};

/**
 * Form Controls Render Types
 */
export const RenderTypes = {
  ReadOnly: 'ReadOnly',
  Disabled: 'Disabled',
  Editable: 'Editable',
};

/**
 * InLine Row Operation Statuses
 */
export const RowStatus = {
  New: 'New',
  Discard: 'Discard',
  Deleted: 'Deleted',
  Modified: 'Modified',
};

export const TokenExpireMessage = [
  'Security token has expired.',
  'Unable to parse JWT token.',
];

/**
 * Display notification settings
 */
export const DisplayNotification = {
  SHOW: 'Show', // show the notification popup and show notification on notification panel
  SILENT: 'Silent', // Do not show the notification popup but notification will be shown in notification panel
  HIDDEN: 'Hidden', //  Do not show the notification popup and notification in notification panel
  ALERT: 'Alert', // Show the notification popup but no notification will be shown in notification panel.
};

/**
 * Modules Constants
 */
export const USERS = 'users';
export const ASSISTANTS = 'assistants';

// Exception Status Codes
export const EXCEPTION_STATUS_CODES = [99];

// Alert Types
export const ALERT_TYPES = {
  ERROR: 'error',
  SUCCESS: 'success',
  INFO: 'info',
  WARNING: 'warning',
  DAFAULTU: 'default',
};

export const ASSISTANTS_TYPES = [
  { text: 'Please Select', value: '' },
  { text: 'OpenAI', value: 'OpenAI' },
  { text: 'Blue Yonder', value: 'BlueYonder' },
  { text: 'Smart AI', value: 'SmartAI' },
  { text: 'Custom', value: 'Custom' },
];

export const CUSTOM_AGENT_WELCOME_MESSAGE = 'Welcome to the Smart Data Insights Assistant! Explore your dataset effortlessly by asking questions and generating detailed charts for analysis. Simply enter your query, and we’ll provide the insights you need.';

