import React from 'react';
import { callBaseApi } from '../../../../Utils/Api/Api';
import MicrosoftLogo from '../../../../assets/logo/microsoft.png';

const AzureLoginButton = ({ setIsLoader, setAlertMessage }) => {
  const handleSocialLogin = async (driver) => {
    setIsLoader(true);
    try {
      const response = await callBaseApi(`/SocialMediaAuth/GetSocialMediaAuthUrl?driver=${driver}`, 'GET');
      if (response?.isSuccess) {
        window.location.href = response.payload.scalar;
      } else {
        setAlertMessage({ alert: true, message: 'Failed to get social login URL. Please try again.' });
      }
    } catch (error) {
      console.error('Get social login URL error:', error);
      setAlertMessage({ alert: true, message: 'Failed to get social login URL. Please try again.' });
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <div className='social-login-box k-p-4 k-pt-0'>
      <hr />
      <button className='microsoft-login-button' onClick={() => handleSocialLogin('azure')}>
        <img src={MicrosoftLogo} alt='Microsoft Logo' />
        Login with Microsoft
      </button>
    </div>
  );
};

export default AzureLoginButton;
