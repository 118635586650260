import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Loader,
  ErrorFallback,
  GlobalProvider,
  AppNotifications,
} from 'smart-react';
import getRoutes from './Routes/Routes';
import './assets/scss/style.scss';
import './assets/scss/common/Responsive.scss';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import '@fortawesome/fontawesome-free/css/solid.css';
import { AuthProvider } from './Modules/Core/Context/AuthContext';
import Favicon from './Modules/Core/Components/Common/Favicon';

const MetaTags = React.lazy(
  () => import('./Modules/Core/Components/MetaTags/Tags')
);
import AuthGaurd from './Modules/Core/Components/AuthGaurd/AuthGaurd';

const DrawerRouterContainer = React.lazy(
  () => import('./Modules/Core/Components/Drawer/DrawerRouterContainer')
);

function App() {
  return (
    <React.Suspense fallback={<Loader />}>
      <Favicon />
      <React.Suspense>
        <MetaTags />
      </React.Suspense>
      <GlobalProvider>
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn't happen again
          }}
        >
          <React.Suspense>
            <AppNotifications />
          </React.Suspense>
        </ErrorBoundary>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<AuthGaurd />}>
                <Route path="/" element={<DrawerRouterContainer />}>
                  {getRoutes('CardLayout')}
                  {getRoutes('TileLayout')}
                </Route>
                {getRoutes('AuthEmptyLayout')}
              </Route>
              {getRoutes('EmptyLayout')}
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </GlobalProvider>
    </React.Suspense>
  );
}

export default App;
