/**
 * Base URL for OpenAI API.
 * @constant {string}
 */
import { callAPI, callGptApi } from '../../../Utils/Api/Api';

const APP_BASE_URL = `${process.env.APP_BASE_URL}`;

/**
 * Creates a new assistant.
 * @async
 * @param {Object} data - Data for creating the assistant.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while creating the assistant.
 */
const createAssistant = async (data) => {
  try {
    const response = await callAPI(`${APP_BASE_URL}/assistants`, 'POST', data);
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error creating assistant: ${error.message}`);
  }
};

/**
 * Generates prompts.
 * @async
 * @param {Object} data - Data for generating prompts.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while generating prompts.
 */
const generatePrompts = async (data) => {
  try {
    const response = await callGptApi(`/chat/completions`, 'POST', data);
    return response;
  } catch (error) {
    throw new Error(`Error generating prompts: ${error.message}`);
  }
};

/**
 * Lists assistants.
 * @async
 * @param {string} [limit=20] - The maximum number of items to return.
 * @param {string} [order='desc'] - The sort order ('asc' or 'desc').
 * @param {number} [offset=1] - The offset for pagination.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while listing assistants.
 */
const listAssistants = async (
  limit = process.env.DefaultLimit,
  order = 'desc',
  offset = 1
) => {
  try {
    const params = new URLSearchParams({
      limit: limit.limit ?? limit,
      order,
      offset: limit.offset ?? offset,
    });
    const response = await callAPI(
      `${APP_BASE_URL}/assistants?${params.toString()}`
    );
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error listing assistants: ${error.message}`);
  }
};

/**
 * Lists assistants by Tenant ID.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while listing assistants.
 * @param id
 */
const listAssistantsByTenantId = async (id) => {
  try {
    const response = await callAPI(`${APP_BASE_URL}/list/assistants/${id}`);
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error listing assistants by ID: ${error.message}`);
  }
};

/**
 * Lists assistants by Asssitant ID.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while listing assistants.
 * @param id
 */
const getAssistantByAssistantId = async (id) => {
  try {
    const response = await callAPI(`${APP_BASE_URL}/list/assistant/id/${id}`);
    return {
      data: response?.payload,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error listing assistants by ID: ${error.message}`);
  }
};

/**
 * Lists assistants.
 * @async
 * @param {string} [limit=20] - The maximum number of items to return.
 * @param {string} [order='desc'] - The sort order ('asc' or 'desc').
 * @param {number} [offset=1] - The offset for pagination.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while listing assistants.
 */
const listTemplates = async (
  limit = process.env.DefaultLimit,
  order = 'desc',
  offset = 1
) => {
  try {
    const params = new URLSearchParams({
      limit: limit.limit ?? limit,
      order,
      offset: limit.offset ?? offset,
    });
    const response = await callAPI(
      `${APP_BASE_URL}/templates?${params.toString()}`
    );
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error listing assistants: ${error.message}`);
  }
};

/**
 * Lists files associated with an assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @param {string} [limit=20] - The maximum number of items to return.
 * @param {string} [order='desc'] - The sort order ('asc' or 'desc').
 * @param {string} [after=""] - The cursor position for pagination.
 * @param {string} [before=""] - The cursor position for pagination.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while listing assistant files.
 */
const listAssistantFiles = async (
  assistantId,
  limit = process.env.DefaultLimit,
  order = 'desc',
  after = '',
  before = ''
) => {
  try {
    const params = new URLSearchParams({ limit, order, after, before });
    const response = await callGptApi(
      `/assistants/${assistantId}/files?${params.toString()}`
    );
    return response;
  } catch (error) {
    throw new Error(`Error listing assistant files: ${error.message}`);
  }
};

/**
 * Retrieves details of a specific assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while retrieving the assistant.
 */
const retrieveAssistant = async (assistantId) => {
  try {
    const response = await callGptApi(`/assistants/${assistantId}`);
    return response;
  } catch (error) {
    throw new Error(`Error retrieving assistant: ${error.message}`);
  }
};

/**
 * Retrieves details of a specific file associated with an assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @param {string} fileId - The ID of the file.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while retrieving the assistant file.
 */
const retrieveAssistantFile = async (assistantId, fileId) => {
  try {
    const response = await callGptApi(
      `/assistants/${assistantId}/files/${fileId}`
    );
    return response;
  } catch (error) {
    throw new Error(`Error retrieving assistant file: ${error.message}`);
  }
};

/**
 * Modifies an existing assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @param {Object} data - Data for modifying the assistant.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while modifying the assistant.
 */
const modifyAssistant = async (assistantId, data) => {
  try {
    const response = await callAPI(
      `${APP_BASE_URL}/assistants/${assistantId}`,
      'POST',
      data
    );
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error modifying assistant: ${error.message}`);
  }
};

/**
 * Deletes an existing assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while deleting the assistant.
 */
const deleteAssistant = async (assistantId) => {
  try {
    const response = await callAPI(
      `${APP_BASE_URL}/assistants/${assistantId}`,
      'DELETE'
    );
    return {
      data: response?.payload?.data,
      TotalCount: response?.totalCount,
      isSuccess: response?.isSuccess,
      message: response?.message,
    };
  } catch (error) {
    throw new Error(`Error deleting assistant: ${error.message}`);
  }
};

/**
 * Deletes a file associated with an assistant.
 * @async
 * @param {string} assistantId - The ID of the assistant.
 * @param {string} fileId - The ID of the file.
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while deleting the assistant file.
 */
const deleteAssistantFile = async (assistantId, fileId) => {
  try {
    const response = await callGptApi(
      `/assistants/${assistantId}/files/${fileId}`,
      'DELETE'
    );
    return response;
  } catch (error) {
    throw new Error(`Error deleting assistant file: ${error.message}`);
  }
};
export {
  createAssistant,
  listAssistants,
  listAssistantsByTenantId,
  listAssistantFiles,
  retrieveAssistant,
  retrieveAssistantFile,
  modifyAssistant,
  deleteAssistant,
  deleteAssistantFile,
  generatePrompts,
  listTemplates,
  getAssistantByAssistantId,
};
