import React from 'react';
import { callBaseApi } from '../../../../Utils/Api/Api';
import GoogleLogo from '../../../../assets/logo/google.png';

const GoogleLoginButton = ({ setIsLoader, setAlertMessage }) => {
  const handleSocialLogin = async (driver) => {
    setIsLoader(true);
    try {
      const response = await callBaseApi(`/SocialMediaAuth/GetSocialMediaAuthUrl?driver=${driver}`, 'GET');
      if (response?.isSuccess) {
        window.location.href = response.payload.scalar;
      } else {
        setAlertMessage({ alert: true, message: 'Failed to get social login URL. Please try again.' });
      }
    } catch (error) {
      console.error('Get social login URL error:', error);
      setAlertMessage({ alert: true, message: 'Failed to get social login URL. Please try again.' });
    } finally {
      setIsLoader(false);
    }
  };

  return (
    <div className='social-login-box k-p-4 k-pt-0'>
      <button className='google-login-button' onClick={() => handleSocialLogin('google')}>
        <img src={GoogleLogo} alt='Google Logo' />
        Login with Google
      </button>
    </div>
  );
};

export default GoogleLoginButton;
