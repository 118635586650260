/**
 * Define the Route List items
 */
import React from 'react';
import NoPermission from '../Modules/Core/NoPermission/NoPermission';
import Splash from '../Modules/Core/Login/Pages/Splash';
import Welcome from '../Modules/Core/Welcome/Welcome';
import SmartAssistant from '../Modules/Assistant/Pages/SmartAssistant';
import SmartChat from '../Modules/SmartChat/Pages/SmartChat';
import Settings from '../Modules/Settings/Pages/Settings';
import FileManagement from '../Modules/Assistant/Components/FileManagement';
import SmartBot from '../Modules/SmartBot/Pages/SmartBot';
import SocialLoginRedirect from '../Modules/Core/Login/Components/SocialLoginRedirect';
import SmartCustomBot from '../Modules/SmartBot/Pages/SmartCustomBot';

const RoutesItemsList = [
  {
    text: 'Dashboard',
    icon: 'fas fa-home',
    route: '/',
    element: <Welcome />,
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Create Assistant',
    icon: 'fas fa-robot',
    route: '/smart-assistant',
    element: <SmartAssistant />,
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Lets Chat',
    icon: 'k-button-icon fas fa-comment',
    route: '/smart-assistant-chat',
    element: <SmartChat />,
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: 'Lets Chat',
    icon: 'k-button-icon fas fa-comment',
    route: '/publishable/assistant/:id',
    element: <SmartBot />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: 'Lets Chat',
    icon: 'k-button-icon fas fa-comment',
    route: '/chat/assistant/:id/:session_id',
    element: <SmartCustomBot />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: 'File Management',
    icon: 'k-button-icon fas fa-comment',
    route: '/files',
    element: <FileManagement />,
    layouts: 'CardLayout',
    is_drawer_item: 'false',
    isauth: true,
    is_mobile: 'true',
  },
  {
    separator: true,
    is_drawer_item: 'true',
  },
  {
    text: 'Settings',
    icon: 'k-button-icon fas fa-cog',
    route: '/setting',
    element: <Settings />,
    layouts: 'CardLayout',
    is_drawer_item: 'true',
    isauth: true,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/login',
    element: <Splash />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Login',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: '',
    icon: '',
    route: '/login',
    element: <Splash />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Login',
    isauth: false,
    is_mobile: 'true',
  },
  {
    id: 14,
    text: 'Social Login Redirect',
    icon: '',
    route: 'social/login/callback',
    element: <SocialLoginRedirect />,
    layouts: 'EmptyLayout',
    is_drawer_item: 'false',
    page_title: 'Social Login Redirect',
    isauth: false,
    is_mobile: 'true',
  },
  {
    text: 'No Permission',
    route: '/no-permission',
    element: <NoPermission />,
    layouts: 'TileLayout',
    is_drawer_item: 'false',
    is_mobile: 'false',
  },
];

const RoutesItems = RoutesItemsList;

export default RoutesItems;
