import React, { useState } from 'react';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartLegend,
} from '@progress/kendo-react-charts';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { Loader } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import './FormatMessage.scss';

// GridComponent functional component
const GridComponent = React.memo(({ metadata, values }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const fileExport = React.useRef(null);
  const excelExport = () => {
    setIsLoader(true);
    if (fileExport.current !== null) {
      fileExport.current.save();
    }
    setIsLoader(false);
  };

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  const renderGrid = (heading, data) => {
    const [page, setPage] = useState({ skip: 0, take: 5 }); // Each page will show a maximum of 7 rows

    // Define columns based on the metadata
    const columns = Array.isArray(heading)
      ? heading.map((col, index) => ({
          field: `col${index}`,
          title: col,
          minResizableWidth: 200,
          width: 200,
        }))
      : [];

    // Prepare rows from data
    const rows = Array.isArray(data)
      ? data.map((row, rowIndex) => {
          const rowData = {};
          row.forEach((cell, cellIndex) => {
            rowData[`col${cellIndex}`] = cell;
          });
          return rowData;
        })
      : [];

    // Paginate rows based on the current page
    const paginatedData = rows.slice(page.skip, page.skip + page.take);

    // Handler for page changes
    const onPageChange = (event) => {
      setPage(event.page);
    };

    return (
      <>
        {isLoader && (
          <div className="loader-wrapper">
            <Loader />
          </div>
        )}
        {rows.length > 0 ? (
          <div className={`grid-container ${isFullScreen ? 'fullscreen' : ''}`}>
            <Button
              className="fullscreen-button ai-button-primary k-cursor-pointer k-mb-1 chat-ex-buttons k-float-right"
              onClick={toggleFullScreen}
            >
              {isFullScreen ? (
                <i className={'fas fa-minimize'} />
              ) : (
                <i className={'fas fa-expand'} />
              )}
            </Button>
            <Button
              className="k-cursor-pointer ai-button-primary k-ml-2 k-mb-1 chat-ex-buttons k-float-right"
              onClick={excelExport}
            >
              <i className={'fas fa-download'} />
            </Button>
            <ExcelExport data={rows} ref={fileExport}>
              <div className="k-clear-both" />
              <div className="grid-wrapper chat">
                <Grid
                  data={paginatedData}
                  size={'small'}
                  resizable={true}
                  className={'data-grid'}
                  scrollable="scrollable" // Makes the data rows scrollable
                  pageable={{
                    previousNext: true, // Show only "Next" and "Previous" buttons
                    buttonCount: 0, // Disable numbered buttons
                    pageSizes: false,
                    info: false, // Hide page information
                  }}
                  skip={page.skip}
                  take={page.take}
                  total={rows.length} // Total number of rows for pagination
                  onPageChange={onPageChange}
                >
                  {columns.map((column) => (
                    <GridColumn
                      key={column.field}
                      field={column.field}
                      title={column.title}
                      minResizableWidth={column.minResizableWidth}
                      width={column.width}
                    />
                  ))}
                </Grid>
              </div>
            </ExcelExport>
          </div>
        ) : (
          <p>No data available!</p> // Display a fallback message if no data
        )}
      </>
    );
  };

  return renderGrid(metadata, values);
});

const PieChartComponent = React.memo(({ metadata, values }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  let chartData;

  if (values.length === 1) {
    // If only one set of values is provided
    chartData = metadata?.reduce((acc, heading, index) => {
      const value = values[0][index];
      if (value !== 0) {
        acc.push({
          category: `${heading} (${value})`,
          value,
        });
      }
      return acc;
    }, []);
  } else {
    // If multiple sets of values are provided
    chartData = values.reduce((acc, entry) => {
      const [category, value] = entry;
      if (value !== 0) {
        acc.push({
          category: `${category} (${value})`,
          value,
        });
      }
      return acc;
    }, []);
  }

  return (
    <div className={`chart-container ${isFullScreen ? 'fullscreen' : ''}`}>
      <Button
        className="fullscreen-button ai-button-primary k-mb-1 chat-ex-buttons k-cursor-pointer k-float-right"
        onClick={toggleFullScreen}
      >
        {isFullScreen ? (
          <i className={'fas fa-minimize'} />
        ) : (
          <i className={'fas fa-expand'} />
        )}
      </Button>
      <div className="k-clear-both" />
      <Chart style={{ width: '100%', height: 400 }}>
        <ChartSeries>
          <ChartSeriesItem
            type="pie"
            data={chartData}
            field="value"
            categoryField="category"
            labels={{
              position: 'outsideEnd',
              content: '{category}: {value}',
              visible: (e) => e.value !== 0, // Hide labels for zero values
            }} // Show labels outside the pie slices
            overlay={{ gradient: 'none' }} // Remove gradient overlay
            startAngle={90} // Start the first slice from the top
            padding={50} // Add padding to create some distance between slices and the edge of the chart
            explodeField="explode" // Use explode field to specify exploded slices
            animation={{ type: 'expand', duration: 1000 }} // Add expand animation
          />
        </ChartSeries>
        <ChartLegend position="bottom" orientation="horizontal" />
      </Chart>
    </div>
  );
});

// ChartImageComponent for rendering a base64 image with its type
const ChartImageComponent = React.memo(({ imageContent, imageType }) => {
  const [isFullScreen, setIsFullScreen] = useState(false);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  return (
    <div className={`image-container ${isFullScreen ? 'fullscreen' : ''}`}>
      <Button
        className="fullscreen-button ai-button-primary k-mb-1 chat-ex-buttons k-cursor-pointer k-float-right"
        onClick={toggleFullScreen}
      >
        {isFullScreen ? (
          <i className={'fas fa-minimize'} />
        ) : (
          <i className={'fas fa-expand'} />
        )}
      </Button>
      <div className="k-clear-both" />
      {imageContent ? (
        <div className={'image-wrapper'}>
          <img
            className={'chart-image'}
            src={`data:${imageType};base64,${imageContent}`} // Use the image type prop
            alt="Chart Image"
          />
        </div>
      ) : (
        <p>No image content available.</p> // Handle case when there's no image
      )}
    </div>
  );
});

export { GridComponent, PieChartComponent, ChartImageComponent };
