import { BYApiCall } from '../../../Utils/Api/Api';

/**
 * Send a message and get the reply.
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const sendBYMessage = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/chat', 'POST', data);
    return response;
  } catch (error) {
    return { message: 'There is some thing wrong Please try again!' };
  }
};

/**
 * get grid Data .
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param session_id
 */
const getSessionGridData = async (session_id) => {
  try {
    const response = await BYApiCall(
      `/warehouse/get-dataframe?session_key=${session_id}&question_suggestion=true`,
      'GET'
    );
    return response;
  } catch (error) {
    return { message: 'There is some thing wrong Please try again!' };
  }
};
/**
 * Initiate Login Request
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const initiateLoginRequest = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/chat', 'POST', data);
    return response;
  } catch (error) {
    return { message: 'There is some thing wrong Please try again!' };
  }
};

/**
 * Login Request
 * @async
 * @returns {Promise<Object>} The response object.
 * @throws {Error} If an error occurs while sending the message.
 * @param data
 */
const BYLogin = async (data) => {
  try {
    const response = await BYApiCall('/warehouse/login', 'POST', data);
    return response;
  } catch (error) {
    return { message: 'There is some thing wrong Please try again!' };
  }
};

export { sendBYMessage, initiateLoginRequest, BYLogin, getSessionGridData };
