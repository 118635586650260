import React, { useState, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { ErrorFallback, Loader } from 'smart-react';
import { Card } from '@progress/kendo-react-layout';
import { listAssistants } from '../../Assistant/Services/AiAssistantService';
import './welcome.scss';
import { useAuth } from '../Context/AuthContext';
import AiContentSlider from '../../Assistant/Components/ContentSlider/AiContentSlider';

/**
 * Show Stats on Welcome Page
 * @returns {React.Element} returns main login screen
 */
const Welcome = () => {
  const [isLoader, setIsLoader] = useState(false);
  const [totalAssistants, setTotalAssistants] = useState(0);
  const { user } = useAuth(); // Get user data from the context
  const [aiSlideShow, setAiSlideShow] = React.useState(true);
  useEffect(() => {
    fetchDashboardData();
  }, []);
  /**
   * Fetch Dashboard stats
   * @returns {React.Element} returns main login screen
   */
  const fetchDashboardData = async () => {
    setIsLoader(true);
    try {
      const assistantsResponse = await listAssistants();
      setTotalAssistants(assistantsResponse.TotalCount);
    } catch (error) {
      addNotification('error', 'Error fetching dashboard data:', error);
    } finally {
      setIsLoader(false);
    }
  };
  const handleEquipmentSlide = () => {
    if (aiSlideShow) {
      setAiSlideShow(false);
    } else {
      setAiSlideShow(true);
    }
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {isLoader && (
        <div className='loader-wrapper'>
          <Loader />
        </div>
      )}
      <div>
        <div>
          <h5 className='k-p-4 k-m-0'>Welcome To Smart Assistant</h5>
        </div>
      </div>

      <div className='dashboard-container'>
        {isLoader ? (
          <div className='loader-wrapper'>
            <Loader />
          </div>
        ) : (
          <div className='dashboard-content'>
            <Card className='dashboard-card'>
              <div className='card-content'>
                <i className='dashboard-icon fas fa-robot' />{' '}
                {/* Icon displayed */}
                <div>
                  <div className='count'>{totalAssistants}</div>
                  {/* Count displayed */}
                  <h3>Total Assistants</h3> {/* Heading displayed */}
                </div>
              </div>
            </Card>
          </div>
        )}
      </div>
    </ErrorBoundary>
  );
};

export default Welcome;
