import React, { useEffect, useState } from 'react';
import { DataTableHoc, ErrorFallback, Loader } from 'smart-react';
import { Button } from '@progress/kendo-react-buttons';
import { useDropzone } from 'react-dropzone';
import { createFormData } from '../../../Utils/Api/Api';
import { buildNotification, GenerateNotification } from 'smart-react';

import {
  EVENTS_DATA_TYPES,
  NOTIFICATION_TYPES,
} from '../../../constants/eventDataTypes';
import {
  createAssistant,
  listAssistants,
  deleteAssistant,
  modifyAssistant,
  listTemplates,
} from '../Services/AiAssistantService';
import {
  ASSISTANTS,
  PAGE_SIZE,
  ROW_LAYOUTS,
  USER_DATA,
} from '../../../constants/applicationConstants';
import AiContentSlider from '../Components/ContentSlider/AiContentSlider';
import {
  applyFilter,
  deleteFilter,
  saveFilters,
} from '../../../Utils/Filters/filterSliderHandler';
import DataColumns from '../Components/DataColumns/DataColumns';
import GridColumnsList from '../Components/Filter/FilterElements';
import AssistantForm from '../Components/Forms/AssistantForm';
import { Dialog } from '@progress/kendo-react-dialogs';
import { TextArea } from '@progress/kendo-react-inputs';
import { ErrorBoundary } from 'react-error-boundary';

const BaseDataTable = React.lazy(
  () => import('../Components/DataTable/DataTable')
);
const DataTable = DataTableHoc(BaseDataTable);

/**
 * Component for managing AI assistants.
 * @returns {JSX.Element} SmartAssistant component.
 */
const SmartAssistant = () => {
  const [assistants, setAssistants] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [assistantName, setAssistantName] = useState('');
  const [assistantInstructions, setAssistantInstructions] = useState('');
  const [isLoader, setIsLoader] = useState(false);
  const [isCreateAssistantDialogOpen, setIsCreateAssistantDialogOpen] =
    useState(false);
  const [isUpdateAssistantDialogOpen, setIsUpdateAssistantDialogOpen] =
    useState(false);
  const [updateAssistantId, setUpdateAssistantId] = useState('');
  const [assistantType, setAssistantType] = useState();
  const [otherAssistantData, setOtherAssistantData] = useState([]);
  const [selectedOption, setSelectedOption] = useState({
    description: '',
    name: 'Please Select',
  });
  const [templates, setTemplates] = useState([]);
  const [isReady, setIsReady] = React.useState(false);
  const [isPublishUrlDialogOpen, setIsPublishUrlDialogOpen] = useState(false);
  const [publishableUrl, setPublishableUrl] = useState('');
  const [isCopied, setIsCopied] = useState(false);

  /**
   * Resets all form fields.
   */
  const resetFields = () => {
    setAssistantName('');
    setAssistantInstructions('');
    setSelectedFiles([]);
    setDroppedFiles([]);
    setSelectedOption({
      description: '',
      name: 'Please Select',
    });
  };
  useEffect(() => {
    fetchTemplates();
  }, []);
  /**
   * Fetches the list of assistants.
   */
  const fetchAssistants = async () => {
    setIsLoader(true);
    try {
      const assistantsResponse = await listAssistants(
        process.env.DefaultLimit,
        'desc'
      );
      if (assistantsResponse.isSuccess) {
        setAssistants(assistantsResponse.data);
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error Fetching Assistants!',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Fetching Assistants!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Fetches the list of assistants.
   */
  const fetchTemplates = async () => {
    setIsLoader(true);
    try {
      const templateResponse = await listTemplates();
      if (templateResponse.isSuccess) {
        setTemplates(templateResponse.data);
      } else {
        GenerateNotification(
          buildNotification({
            title: 'Error Fetching Templates!',
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Fetching Templates!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Creates a new assistant with attached files.
   */
  const handleCreateAssistant = async (data) => {
    setIsLoader(true);
    setIsCreateAssistantDialogOpen(false);
    try {
      let formData = '';
      if (assistantType === 'BlueYonder' || assistantType == 'Custom') {
        formData = createFormData({
          name: assistantName,
          data: JSON.stringify(data),
          type: assistantType,
        });
      } else if (assistantType === 'SmartAI') {
        formData = createFormData({
          name: assistantName,
          type: assistantType,
          ...(droppedFiles.length > 0 ? { new_files: droppedFiles } : {}),
        });
      } else {
        formData = createFormData({
          name: assistantName,
          instructions: assistantInstructions,
          type: assistantType,
          tools: JSON.stringify([{ type: 'file_search' }]),
          model: 'gpt-4o',
          ...(droppedFiles.length > 0 ? { new_files: droppedFiles } : {}),
        });
      }
      const assistantResponse = await createAssistant(formData);
      if (assistantResponse.isSuccess) {
        await fetchAssistants();
        resetFields();
        GenerateNotification(
          buildNotification({
            title: assistantResponse.message,
            description: '',
            style: 'success',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification({
            title: assistantResponse.message,
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Creating Assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Updates an existing assistant with attached files.
   */
  const handleUpdateAssistant = async (data) => {
    setIsLoader(true);
    setIsUpdateAssistantDialogOpen(false);
    try {
      let formData = '';
      if (assistantType === 'BlueYonder') {
        formData = createFormData({
          name: assistantName,
          data: JSON.stringify(data),
          type: assistantType,
        });
      } else if (assistantType === 'SmartAI') {
        formData = createFormData({
          name: assistantName,
          type: assistantType,
          files: JSON.stringify(selectedFiles),
          ...(droppedFiles.length > 0 ? { new_files: droppedFiles } : {}),
        });
      } else {
        formData = createFormData({
          name: assistantName,
          instructions: assistantInstructions,
          type: assistantType,
          files: JSON.stringify(selectedFiles),
          ...(droppedFiles.length > 0 ? { new_files: droppedFiles } : {}),
        });
      }

      const assistantResponse = await modifyAssistant(
        updateAssistantId,
        formData
      );
      resetFields();
      if (assistantResponse.isSuccess) {
        await fetchAssistants();
        resetFields();
        GenerateNotification(
          buildNotification({
            title: assistantResponse.message,
            description: '',
            style: 'success',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification({
            title: assistantResponse.message,
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Updating Assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
      setIsUpdateAssistantDialogOpen(true);
    } finally {
      setIsLoader(false);
      setIsUpdateAssistantDialogOpen(false);
    }
  };
  /**
   * Generates a publishable URL for the assistant(s).
   * @param {object} [assistant] - The assistant object to generate the URL for. If not provided, generate URL for all assistants.
   */
  const publishUrl = async (assistant) => {
    try {
      let combinedInfo;
      if (assistant) {
        combinedInfo = JSON.stringify({
          name: assistant.name,
          assistant_id: assistant.assistant_id,
          type: assistant.type,
          data: assistant.data,
        });
      } else {
        const storageData = JSON.parse(
          sessionStorage.getItem(USER_DATA)
        ).tenant_id;
        combinedInfo = JSON.stringify({ tenant_id: storageData });
      }

      const base64Info = btoa(combinedInfo);
      const publishableUrl = `<script src='https://ois-ai-assistants.azurewebsites.net/script/smartchat.js?code=${base64Info}'></script>`;
      setPublishableUrl(publishableUrl);
      setIsPublishUrlDialogOpen(true);
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Generating Publishable URL!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    }
  };

  /**
   * Deletes an assistant and its attached files.
   * @param assistant
   */
  const handleDelete = async (assistant) => {
    setIsLoader(true);
    try {
      // Delete the assistant
      const response = await deleteAssistant(assistant.id);
      if (response?.isSuccess) {
        GenerateNotification(
          buildNotification({
            title: 'Assistant deleted successfully!',
            description: '',
            style: 'success',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      } else {
        GenerateNotification(
          buildNotification({
            title: response.message,
            description: '',
            style: 'error',
          }),
          NOTIFICATION_TYPES.APP,
          EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
        );
      }
      await fetchAssistants();
    } catch (error) {
      GenerateNotification(
        buildNotification({
          title: 'Error Deleting Assistant!',
          description: '',
          style: 'error',
        }),
        NOTIFICATION_TYPES.APP,
        EVENTS_DATA_TYPES.APPLICATION_NOTIFICATION
      );
    } finally {
      setIsLoader(false);
    }
  };

  /**
   * Configuration for the Dropzone component.
   */
  const { getRootProps, getInputProps } = useDropzone({
    accept: '.pdf,.jsonl',
    onDrop: (acceptedFiles) => {
      setDroppedFiles((prevFiles) => [...prevFiles, ...acceptedFiles]);
    },
  });

  /**
   * Deletes a file from the list of selected files.
   * @param {string} fileId - The ID of the file to delete.
   */
  const handleDeleteFile = (fileId) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((file) => file.id !== fileId)
    );
  };

  /**
   * Handles editing an assistant.
   * @param {Object} assistant - The assistant object to edit.
   */
  const handleEdit = async (assistant) => {
    setIsUpdateAssistantDialogOpen(true);
    setAssistantName(assistant.name);
    setAssistantInstructions(assistant.instructions);
    setUpdateAssistantId(assistant.id);
    setAssistantType(assistant.type);
    setOtherAssistantData(JSON.parse(assistant?.data));
    setIsUpdateAssistantDialogOpen(true);
    setSelectedFiles(assistant.files);
  };

  /**
   * Opens the dialog for creating a new assistant.
   */
  const handleCreateAssistantDialog = () => {
    setIsCreateAssistantDialogOpen(true);
    resetFields();
  };

  /**
   * Cancels the creation of a new assistant and resets fields.
   */
  const handleCancelCreateAssistant = () => {
    setIsCreateAssistantDialogOpen(false);
    resetFields();
  };

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <div className=" k-pr-5 k-pl-5 k-mt-3">
        <React.Suspense fallback={<Loader />}>
          <h4 className="k-ml-3">Assistant Management</h4>
          <div className="k-mt-5 k-mr-3">
            <Button
              className="ai-button-primary k-float-right"
              onClick={handleCreateAssistantDialog}
            >
              Create New Assistant
            </Button>
            <Button
              onClick={() => publishUrl()}
              className="k-ml-3 ai-button-primary"
            >
              Publish All Profiles
            </Button>
          </div>
          <div className="k-clear-both" />
          <div className="k-mb-3 k-mt-3">
            <DataTable
              deleteFilter={deleteFilter}
              applyFilter={applyFilter}
              saveFilters={saveFilters}
              moduleName={ASSISTANTS}
              dataColumns={DataColumns}
              gridColumnsList={() =>
                GridColumnsList({ handleEdit, handleDelete, publishUrl })
              }
              dataTableName={ASSISTANTS}
              setIsReady={setIsReady}
              isReady={isReady}
              rowLayoutConfigName={`${ASSISTANTS}.DataGrid.RowLayout`}
              pageLengthConfigName={`${ASSISTANTS}.DataGrid.ItemsPerPage`}
              dataSet={assistants}
              setDataSet={setAssistants}
              isCardonMobileView={false}
              total={process.env.DefaultLimit}
              defaultRowLayout={process.env.RowLayout}
              rowLayouts={ROW_LAYOUTS}
              pageSizes={PAGE_SIZE}
            />
          </div>
          {isLoader && (
            <div className="loader-wrapper">
              <Loader />
            </div>
          )}
          {isCreateAssistantDialogOpen && (
            <AiContentSlider show={isCreateAssistantDialogOpen}>
              <AssistantForm
                assistant={{
                  assistantName,
                  setAssistantName,
                  assistantInstructions,
                  setAssistantInstructions,
                  droppedFiles,
                  setDroppedFiles,
                  selectedFiles,
                  setSelectedFiles,
                  templates,
                  selectedOption,
                  setAssistantType,
                  assistantType,
                  setSelectedOption,
                  otherAssistantData,
                  setOtherAssistantData,
                }}
                actions={{
                  handleCancel: handleCancelCreateAssistant,
                  handleSubmit: (data) => {
                    handleCreateAssistant(data);
                  },
                  handleSlide: handleCancelCreateAssistant,
                }}
                title="Create Assistant"
              />
            </AiContentSlider>
          )}
          {isUpdateAssistantDialogOpen && (
            <AiContentSlider
              show={isUpdateAssistantDialogOpen}
              handleSlide={() => {
                setIsUpdateAssistantDialogOpen(false);
                resetFields();
              }}
            >
              <AssistantForm
                assistant={{
                  assistantName,
                  setAssistantName,
                  assistantInstructions,
                  setAssistantInstructions,
                  droppedFiles,
                  handleDeleteFile,
                  setDroppedFiles,
                  selectedFiles,
                  setSelectedFiles,
                  templates,
                  selectedOption,
                  setSelectedOption,
                  setAssistantType,
                  assistantType,
                  otherAssistantData,
                  setOtherAssistantData,
                  isUpdate: true,
                }}
                actions={{
                  handleCancel: () => {
                    setIsUpdateAssistantDialogOpen(false);
                    resetFields();
                  },
                  handleSubmit: handleUpdateAssistant,
                  handleSlide: () => {
                    setIsUpdateAssistantDialogOpen(false);
                  },
                }}
                title="Update Assistant"
              />
            </AiContentSlider>
          )}
          {/* Publish URL Dialog */}
          {isPublishUrlDialogOpen && (
            <Dialog
              title={'Publish Assistant URL'}
              width={400}
              onClose={() => setIsPublishUrlDialogOpen(false)}
            >
              <div className="k-d-flex k-flex-column">
                <div className="k-d-flex k-flex-column k-align-items-center">
                  {isCopied && <p>Copied!</p>}
                  <TextArea
                    value={publishableUrl}
                    readOnly
                    rows={4}
                    autoSize={true}
                  />
                  <Button
                    icon="copy"
                    className="ai-button-primary k-mt-2"
                    style={{ width: '100%' }}
                    onClick={() => {
                      navigator.clipboard.writeText(publishableUrl);
                      setIsCopied(true);
                      setTimeout(() => setIsCopied(false), 2000); // Reset after 2 seconds
                    }}
                    title="Copy URL"
                  />
                </div>
              </div>
            </Dialog>
          )}
        </React.Suspense>
      </div>
    </ErrorBoundary>
  );
};
export default SmartAssistant;
