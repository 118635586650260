import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import './FormatMessageContent.scss';

// Function to check if a string starts with simple HTML tags
const startsWithHTML = (content) => {
  // Check if the content starts with HTML tags
  const htmlTagPattern = /^<([a-z][a-z0-9]*)\b[^>]*>/i;
  return htmlTagPattern.test(content.trim());
};

// Custom component to render HTML safely
const RenderHtml = ({ htmlContent }) => {
  const createMarkup = (html) => ({ __html: html });
  return <div dangerouslySetInnerHTML={createMarkup(htmlContent)} />;
};

const FormatMessageContent = ({ markdownContent }) => {
  const [copyStatus, setCopyStatus] = useState({});

  // Define custom components for rendering Markdown
  const components = {
    h1: ({ node, children }) => <h1>{children}</h1>,
    h2: ({ node, children }) => <h2>{children}</h2>,
    h3: ({ node, children }) => <h3>{children}</h3>,
    h4: ({ node, children }) => <h4>{children}</h4>,
    h5: ({ node, children }) => <h5>{children}</h5>,
    h6: ({ node, children }) => <h6>{children}</h6>,
    p: ({ node, children }) => <p>{children}</p>,
    ul: ({ node, children }) => <ul>{children}</ul>,
    ol: ({ node, children }) => <ol>{children}</ol>,
    li: ({ node, children }) => <li>{children}</li>,
    a: ({ node, href, children }) => <a href={href}>{children}</a>,
    code: ({ node, inline, className, children, ...props }) => {
      if (inline) {
        return <code className={className} {...props}>{children}</code>;
      }

      const codeString = String(children).replace(/\n$/, '');
      const language = className ? className.split('-')[1] : '';

      const handleCopyClick = (event) => {
        const codeBlockDiv = event.target.closest('.code-block');
        const codeContent = codeBlockDiv.querySelector('pre').innerText;
        navigator.clipboard
          .writeText(codeContent)
          .then(() => {
            setCopyStatus((prevStatus) => ({
              ...prevStatus,
              [codeString]: 'Copied!',
            }));
            setTimeout(() => {
              setCopyStatus((prevStatus) => ({
                ...prevStatus,
                [codeString]: '',
              }));
            }, 2000);
          })
          .catch(() => {
            setCopyStatus((prevStatus) => ({
              ...prevStatus,
              [codeString]: 'Failed to copy',
            }));
            setTimeout(() => {
              setCopyStatus((prevStatus) => ({
                ...prevStatus,
                [codeString]: '',
              }));
            }, 2000);
          });
      };

      return (
        <div className='code-block'>
          <div className='code-block-header'>
            {copyStatus[codeString] && (
              <span className='copy-status'>{copyStatus[codeString]}</span>
            )}
            <button className='copy-button' onClick={handleCopyClick}>
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </div>
          <div className='code-content'>
            <SyntaxHighlighter
              style={okaidia}
              language={language}
              showLineNumbers={true}
            >
              {codeString}
            </SyntaxHighlighter>
          </div>
        </div>
      );
    },
  };

  return (
    <div className='content-renderer'>
      {startsWithHTML(markdownContent) ? (
        <RenderHtml htmlContent={markdownContent} />
      ) : (
        <ReactMarkdown components={components}>
          {markdownContent}
        </ReactMarkdown>
      )}
    </div>
  );
};

export default FormatMessageContent;
