import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../Context/AuthContext';
import { callBaseApi } from '../../../../Utils/Api/Api';
import { ACCESS_TOKEN, REFRESH_TOKEN, TOKEN_EXP, USER_DATA } from '../../../../constants/applicationConstants';

const SocialLoginRedirect = () => {
  const navigate = useNavigate();
  const { updateUser, checkTokens } = useAuth();
  const [message, setMessage] = useState('Redirecting...');
  const [authenticating, setAuthenticating] = useState(true);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);

    // Check if auth code and driver are present in query params
    const authCode = query.get('code');
    const driver = query.get('state');

    if (authCode && driver) {
      verifyAuthCode(authCode, driver);
    } else if (checkTokens()) {
      window.location.replace('/');
    } else {
      navigate('/login');
    }
  }, [navigate, checkTokens]);

  const verifyAuthCode = async (code, driver) => {
    try {
      const response = await callBaseApi('/SocialMediaAuth/GetAuthCode', 'POST', { code, driver });
      if (response?.isSuccess) {
        const data = response.payload;
        sessionStorage.setItem(ACCESS_TOKEN, data.token.access_token);
        sessionStorage.setItem(REFRESH_TOKEN, data.token.refresh_token);
        sessionStorage.setItem(TOKEN_EXP, data.token.expires_in);
        sessionStorage.setItem(USER_DATA, JSON.stringify(data));
        updateUser(data);
        window.location.replace('/');
      } else {
        handleAuthenticationError('Failed to authenticate.');
      }
    } catch (error) {
      handleAuthenticationError('Authentication failed. Please try again.');
    }
  };

  const handleAuthenticationError = (errorMessage) => {
    setAuthenticating(false); // Stop showing "Please wait" message
    setMessage(errorMessage); // Update the message state with the error
    setTimeout(() => navigate('/login'), 3000); // Redirect to login after 5 seconds
  };

  return (
    <div>
      {authenticating && (
        <div>
          <h6>{message}</h6>
          <p>Please wait while we complete your authentication.</p>
        </div>
      )}
      {!authenticating && (
        <div>
          <h6>{message}</h6>
          <h6>Redirecting....</h6>
        </div>
      )}
    </div>
  );
};

export default SocialLoginRedirect;
